import { Controller } from "stimulus";

function confirmCardPayment(clientSecret) {
  stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: card,
      billing_details: {
        name: 'Jenny Rosen'
      }
    },
    setup_future_usage: 'off_session'
  }).then(function(result) {
    if (result.error) {
      // Show error to your customer
      alert(result.error.message);

    } else {
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback execution
        // Set up a webhook or plugin to listen for the payment_intent.succeeded event
        // to save the card to a Customer

        // The PaymentMethod ID can be found on result.paymentIntent.payment_method
        paymentMethodHandler(result.paymentIntent.payment_method)

        return
      }

      //TODO: error handling for user
    }
  });
}


// Submit the form with the token ID.
function paymentMethodHandler(paymentMethod) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'payment_method');
  hiddenInput.setAttribute('value', paymentMethod);
  form.appendChild(hiddenInput);

  // Submit the form
  form.submit();
}


var Stripe;
export default class extends Controller {
  static values = {
    stripepk: String,
    intentClientSecret: String,
    hasCard: Boolean,
  }

  static targets = [
    "package",
    "name",
    "plancolumn",
    "ccform",
    "paymentform",
    "intentid",
    "paymentmethodid",
    "plancontainer",
    "switchPlanButton",
    "saveCardButton",
    "cardError"
  ]

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  static style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  setupStripe() {
    Stripe = window.Stripe;

    var intentClientSecret = this.intentClientSecretValue;

    this.stripe = Stripe(this.stripepkValue);
    this.elements = this.stripe.elements();

    this.card = this.elements.create('card', {style: this.style});
    this.card.mount('#card-element');

    this.card.on('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  connect() {
    window.onload = () => {
      // Only call setup stripe once we finish loading stripe sdk
      this.setupStripe()
    }
  }

  upgradeplan(event) {
    const e = event.currentTarget;
    e.classList.add("is-loading");
    e.disabled = true;

    this.paymentformTarget.submit();
  }

  savecard(event) {
    event.preventDefault();

    this.saveCardButtonTarget.classList.add("is-loading");
    this.saveCardButtonTarget.disabled = true;
    this.confirmCardSetup();
  }

  confirmCardSetup(card, clientSecret, name) {
    this.stripe.confirmCardSetup(
      this.intentClientSecretValue,
      {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.nameTarget.value,
          },
        },
      }
    ).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.saveCardButtonTarget.classList.remove("is-loading");
        this.saveCardButtonTarget.disabled = false;
        this.cardErrorTarget.innerHTML = result.error.message;
      } else {
        // The setup has succeeded. Display a success message.
        this.intentidTarget.value = result.setupIntent.id
        this.paymentmethodidTarget.value = result.setupIntent.payment_method
        this.paymentformTarget.submit()
      }
    });
  }

  setpackage(event) {
    const element = event.target;
    this.package = element.dataset.package;
    this.packageTarget.value = this.package;

    this.plancolumnTargets.forEach(element => {
      element.classList.remove("is-selected");
      if (element.dataset.package == this.package) {
        element.classList.add("is-selected");
      }
    })

    if (this.hasCardValue) {
      // No need to show credit card form bcuz they already has the card on file. If user need to update card, they will click other button
      // that show the card form only
      this.switchPlanButtonTarget.disabled = false;
      this.ccformTarget.classList.add("invisible");
    } else {
      this.ccformTarget.classList.remove("invisible");
      location.hash = "#ccform"
    }
  }

  showplanform(event) {
    this.plancontainerTarget.classList.remove("invisible");
    location.hash = "#pricing"
  }

  showcardform(event) {
    this.ccformTarget.classList.remove("invisible");
    location.hash = "#ccform"
  }
}
