import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
    runable: String,
  }

  static targets = [
    "output",
    "spinner",

  ]

  connect() {
    if (this.runableValue == "true") {
      this.setupPool()
    }
  }

  setupPool() {
    this.poolInterval = setInterval(this.pool.bind(this), 3000)
  }

  pool() {
    fetch(this.urlValue, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },

    })
      .then(response => response.json())
      .then(data => {
        this.outputTarget.innerHTML = data.output;

        //this.outputTarget.scrollTop = this.outputTarget.scrollHeight;
        window.scrollTo(0,document.body.scrollHeight-300);

        if (data.status == "success") {
          clearInterval(this.poolInterval);
          this.spinnerTarget.remove("is-loading")
          this.spinnerTarget.innerHTML("Finished processing")
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
}
