import { Controller } from "stimulus";
import * as log from 'loglevel';

export default class extends Controller {
  static values = {
  }

  static targets = [
    "csrf"
  ]

  toggleDomain() {
    const t = event.currentTarget
    const domainid = t.dataset.domainid

    fetch(t.dataset.toggleurl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.csrfTarget.value,
      }}).then(resp => resp.text())
      .then(html => {
        const cell = document.querySelector(`[data-domainid="${domainid}"]`)
        cell.outerHTML = html;
      })
  }
}
