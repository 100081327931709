import { Controller } from "stimulus";
import * as log from 'loglevel';

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    domainid: Number,

    logurl: String,
  }

  static targets = [
    "addHeaderBtn",

    "headerContainer",
    "headerForm",

    "logview",
    "logline",
  ]

  addHeader(event) {
    const clone = this.headerFormTarget.cloneNode(true)

    clone.classList.remove("is-hidden")
    delete clone.dataset.webhookTarget

    clone.dataset.headerid=`new-header-${Date.now()}-${Math.random()}`
    const remButton = clone.querySelector("[data-role='remove-header-button']")
    remButton.dataset.headerid = clone.dataset.headerid

    this.headerContainerTarget.before(clone)
  }

  removeHeaderElement(event) {
    const t = event.currentTarget
    const id = t.dataset.headerid
    const cell = document.querySelector(`[data-headerid="${id}"]`)
    cell.remove()
  }

  loadmore(event) {
    var e = document.querySelectorAll(".webhook-log-item:last-of-type")[0]
    if (!e || !e.dataset.logid) {
      return
    }

    //var e = this.loglineTargets[this.loglineTargets.length-1]
    const t = event.currentTarget
    t.classList.toggle("is-loading")

    t.disabled = true
    fetch(this.logurlValue + "?before=" + (parseInt(e.dataset.logid) - 1))
      .then(response => response.text())
      .then(html => {
        this.logviewTarget.innerHTML += html
        t.classList("is-loading")
        t.disabled = false
      })
      .catch(e => {
        t.classList.toggle("is-loading")
        t.disabled = false
      })
  }
}
