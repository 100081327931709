import { Controller } from "stimulus";
import * as log from 'loglevel';

export default class extends Controller {
	static values = {
		loggedin: Boolean,
	}

	static targets = [
		"navMenuIndex"
  ]

  toggleNavbar() {
    this.navMenuIndexTarget.classList.toggle('is-active');
  }
}
