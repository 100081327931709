import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
  }

  static targets = [
  ]

  connect() {
  }

  loadRemote(event) {
    const t = event.currentTarget

    t.classList.toggle("is-loading")

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    if (t.dataset.remoteCsrf) {
      headers['X-CSRF-Token'] = t.dataset.remoteCsrf
    }

    fetch(t.dataset.remoteUrl, {
        method: t.dataset.remoteMethod || "GET",
        credentials: 'same-origin',
        headers: headers,
      })
      .then(response => response.text())
      .then(html => {
        const target = document.getElementById(t.dataset.remoteElement)
        t.classList.toggle("is-loading")

        if (target) {
          target.innerHTML = html
        }
      })
      .catch(e => console.log(e))
  }
}
