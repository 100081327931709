import { Controller } from "stimulus";
import * as log from 'loglevel';

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    domain: String,
    spfurl: String,
    mxurl: String,
    csrf: String,
  }

  static targets = [
    "checkmx",
    "checkspf",
    "resultMx",
    "resultSpf",
    "modal",
    "modalBody",
  ]

  connect() {
  }

  checkmx(event) {
    event.currentTarget.classList.toggle("is-loading")
    this.resultMxTarget.innerHTML = ""

    fetch(this.mxurlValue)
      .then(response => response.text())
      .then(html => {
        this.resultMxTarget.innerHTML = html
        this.checkmxTarget.classList.toggle("is-loading")
      })
  }

  checkspf(event) {
    event.currentTarget.classList.toggle("is-loading")
    this.resultSpfTarget.innerHTML = ""

    fetch(this.spfurlValue)
      .then(response => response.text())
      .then(html => {
        this.resultSpfTarget.innerHTML = html
        this.checkspfTarget.classList.toggle("is-loading")
      })
  }

  copycontent(event) {
    const t = event.currentTarget;

    navigator.clipboard.writeText(t.dataset.clipboard).then(() => {
      //t.innerHTML = "<code>Copied</code>&nbsp;" + t.dataset.tooltip;
      const original = t.dataset.tooltip;
      t.dataset.tooltip = "Copied";
      setTimeout(() => {
        t.dataset.tooltip = original;
      }, 3000);
    }, () => {
      alert("Fail to copy to clipboard");
    })
  }

  sendTestEmail(event) {
    const t = event.currentTarget

    t.classList.toggle("is-loading")

    fetch(t.dataset.url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': this.csrfValue,
        },

      })
      .then(response => response.text())
      .then(html => {
        t.classList.toggle("is-loading")
        this.modalTarget.classList.toggle("is-active")
        this.modalBodyTarget.innerHTML = html
      })
      .catch(e => console.log(e))
  }

  closeModal() {
    this.modalBodyTarget.innerHTML = ""
    this.modalTarget.classList.toggle("is-active")
  }
}
