import { Controller } from "stimulus";
import * as log from 'loglevel';

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    content: String,
  }

  static targets = [
    "checkmx",
    "checkspf",
    "resultMx",
    "resultSpf",
    "modal",
    "modalBody",
  ]

  copycontent(event) {
    const t = event.currentTarget;

    navigator.clipboard.writeText(t.dataset.clipboard).then(() => {
      //t.innerHTML = "<code>Copied</code>&nbsp;" + t.dataset.tooltip;
      const original = t.dataset.tooltip;
      t.dataset.tooltip = "Copied";
      setTimeout(() => {
        t.dataset.tooltip = original;
      }, 3000);
    }, () => {
      alert("Fail to copy to clipboard");
    })
  }
}
