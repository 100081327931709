import { Controller } from "stimulus";
import * as log from 'loglevel';

import DOMPurify from 'dompurify';

export default class extends Controller {
	static values = {
		mails: String,
	}

	static targets = [
    "mailviewer",
    "mailrow",

    "replybox",
    "replytoid",
    "replytosubject",

    "from",
    "subject",
    "to",
	]

	connect() {
    this.purifyOptions = {USE_PROFILES: {html: true}, ALLOW_DATA_ATTR: false}
    this.safeHTML = {}
	}

	read(event) {
    const element = event.currentTarget;
		const mailid  = parseInt(element.dataset.mailid);

    this.mailrowTargets.forEach(elem => elem.classList.remove("is-active"))
    element.classList.add("is-active")

    const mail = this.mails.find(m => { return m.id == mailid })

    if (!this.safeHTML[mailid]) {
      this.safeHTML[mailid] = DOMPurify.sanitize(mail.body, this.purifyOptions)
    }
    this.mailviewerTarget.innerHTML =  this.safeHTML[mailid]

    this.fromTarget.innerHTML = `<strong>${this.escapeHtml(element.dataset.from)}</strong>`
    const safeSubject = this.escapeHtml(element.dataset.subject)
    this.subjectTarget.innerHTML = safeSubject
    this.toTarget.innerHTML = `<span class="tag">To:</span> ${this.escapeHtml(element.dataset.to)}`

    if (this.hasReplyboxTarget) {
      this.replyboxTarget.classList.remove("is-hidden")
      this.replytoidTarget.value = mailid

      if (safeSubject.startsWith("Re:")) {
        this.replytosubjectTarget.value = safeSubject
      } else {
        this.replytosubjectTarget.value = "Re: " + safeSubject
      }
    }
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

}
