import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    accountid: String,
    url: String,
  }

  static targets = [
    "password",
  ]

  showPassword(event) {
    //event.currentTarget.classList.toggle("is-loading")
    this.passwordTarget.classList.toggle("is-hidden")
  }
}
