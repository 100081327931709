import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
  }

  static targets = [
    "docitem"
  ]

  viewdoc(event) {
    const t = event.currentTarget;

    this.docitemTargets.forEach(e => {
      e.classList.remove("is-active")
    })

    t.classList.toggle("is-active")
  }
}
