import { Controller } from "stimulus";

import DOMPurify from 'dompurify';

export default class extends Controller {
  static values = {
    email: String,
    fetchUrl: String,
    fetchInterval: Number,
  }

  static targets = [
    "mailinbox",
    "maildisplay",
    "refreshbutton",
    "mailrow",

    "from",
    "subject",
    "to",
  ]

  connect() {
    this.isFirstLoad = true

    this.purifyOptions = {USE_PROFILES: {html: true}, ALLOW_DATA_ATTR: false}

    this.isFetching = false
    this.fetch()

    if (this.fetchIntervalValue) {
      this.counter = this.fetchIntervalValue
      //setInterval(this.refresh.bind(this), 1000)
    }
  }

  refresh() {
    if (this.isFetching) {
      return
    }

    this.refreshbuttonTarget.innerHTML = `Refresh in ${this.counter}s`
    this.counter = this.counter - 1

    if (this.counter < 0) {
      this.fetch()
    }
  }

  fetch() {
    this.isFetching = true
    this.refreshbuttonTarget.classList.toggle("is-loading")

    fetch(this.fetchUrlValue)
      .then(response => response.text())
      .then(html => {
        this.mailinboxTarget.innerHTML = html
        this.refreshbuttonTarget.classList.toggle("is-loading")
        if (this.mailrowTargets[0]) {
          if (this.isFirstLoad) {
            this.showMailOfRow(this.mailrowTargets[0])
            this.isFirstLoad = false
          }
        }
        this.isFetching = false
        this.counter = 10
      })
  }

  showMailOfRow(element) {
    const mailid  = parseInt(element.dataset.mailid);

    this.mailrowTargets.forEach(elem => elem.classList.remove("is-active"))
    element.classList.add("is-active")

    this.fromTarget.innerHTML = `<strong>${this.escapeHtml(element.dataset.from)}</strong>`
    this.subjectTarget.innerHTML = this.escapeHtml(element.dataset.subject)
    //this.toTarget.innerHTML = `<span class="tag">To:</span> <span class=""${this.escapeHtml(element.dataset.to)}`
    this.maildisplayTarget.innerHTML = DOMPurify.sanitize(element.dataset.body, this.purifyOptions)
  }

  showMail(event) {
    const element = event.currentTarget
    this.showMailOfRow(element)
  }

  copyAddress() {
    const t = event.currentTarget;

    navigator.clipboard.writeText(this.emailValue + "@magicmail.run").then(() => {
      t.innerHTML = "Copied"
      setTimeout(() => {
        t.innerHTML = "Copy Address"
      }, 3000);
    }, () => {
      alert("Fail to copy to clipboard");
    })

  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
}
